import React, { useState, useEffect } from 'react';
import { HUBSPOT_API_KEY } from '../../config/entrypoint.js';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginTop: '5px'
  },
  padding: {
    '&:last-child': {
      paddingBottom: '16px'
    }
  },
  title: {
    fontSize: 14
  }
});

const DealCountCard = () => {
  const [data, setData] = useState({
    count: 'loading...'
  });

  useEffect(() => {
    const month = 1000 * 60 * 60 * 24 * 30;
    const monthTime = new Date(Date.now() - month).getTime();
    const fetchData = async () => {
      const result = await fetch(
        `https://cors-anywhere.herokuapp.com/https://api.hubapi.com/contacts/search/v1/external/lifecyclestages?hapikey=${HUBSPOT_API_KEY}&fromTimestamp=${monthTime}&toTimestamp=${Date.now()}`
      )
        .then(x => x.json())
        .then(data => {
          for (let x in data) {
            if (data[x]['lifecycleStage'] == 'hs_lifecyclestage_lead_date') {
              return {
                count: data[x]['count']
              };
            }
          }
          throw 'Error with Hubspot API call';
        })
        .catch(e => {
          console.log(e);
          return { count: 'Hubspot API error' };
        });

      setData(result);
    };
    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.padding}>
        <Typography variant="h6" component="h2" align="center">
          New leads in the past 30 days: {data.count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DealCountCard;
