import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios/index';
import { ENTRYPOINT } from '../../../../config/entrypoint';
import { UploadFile } from '../../UploadFile';
import { getToken } from '../../../../utils/auth';

// style

const fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const ModalContentWrapper = styled.div`
  animation: ${fadein} 500ms;

  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 753px;
`;

const ModalTitle = styled.div`
  background-color: #fff;
  padding: 18px 56px 5px 18px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #52595f;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
`;

const FormCreateContainer = styled.div`
  padding: 20px;
  padding-bottom: 0;
  input {
    background: #ccc;
  }

  textarea {
    background: #ccc;
  }

  select {
    background: #ccc;
  }
`;

const ClientMappingSelectContainer = styled.div`
  display: ${props => {
    return props.isHideClientMapping ? 'none' : 'block';
  }};
  select {
    border-color: ${props => {
      return props.onInvalidClientMapping ? 'red' : 'transparent';
    }};
  }
`;

const SummaryInput = styled.input`
  border-color: ${props => {
    return props.onInvalidSummary ? 'red' : 'transparent';
  }};
`;

const ModalContent = styled.div`
  background-color: #ffffff;

  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% - 76px);

  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  margin: 10px;
`;

const SaveChangesButton = styled.input`
  display: block;
  color: white;
  transition: all 0.3s;
  background: #ff6a00;
  box-shadow: unset !important;

  // override Bootstrap style
  &,
  &.btn:disabled {
    background-color: #ff6a00;
    border-color: #ff6a00;
    box-shadow: unset;
  }

  &:hover {
    color: white;
  }

  &.active {
    background-color: #ff6a00;
    border-color: #ff6a00;
    box-shadow: unset;

    &:hover {
      color: white;
    }
  }
`;

const CancelButton = styled.input`
  display: block;
  color: #ff6a00;
  transition: all 0.3s;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 3px;
  text-transform: uppercase;
  &.btn:disabled {
    background-color: #fff;
    border-color: #fff;
    box-shadow: unset;
  }

  &:hover {
    color: #ff6a00;
  }

  &.active {
    background-color: #fff;
    border-color: #fff;
    box-shadow: unset;

    &:hover {
      color: #ff6a00;
    }
  }
`;

const ModalCreateTicket = styled.div`
  width: 100%;
`;

// main

const CreateTicket = ({ clientMappings, hideModal, handleReload }) => {
  const [submitSent, setSubmitSent] = useState(false);
  const [summary, setSummary] = useState('');
  const [description, setDescription] = useState('');
  const [attachment, setAttachment] = useState([]);
  const [clientMapping, setClientMapping] = useState(null);
  const [isHideClientMapping, setIsShowClientMapping] = useState(false);
  const [onInvalidSummary, setOnInvalidSummary] = useState(false);
  const [onInvalidClientMapping, setOnInvalidClientMapping] = useState(false);

  useEffect(() => {
    setIsShowClientMapping(clientMappings.length === 1);
  }, [isHideClientMapping]);

  const handleSubmitChange = event => {
    let clientMappingSelected = isHideClientMapping
      ? clientMappings[0].id
      : clientMapping;
    let values = {
      summary: summary,
      description: description,
      clientMapping: clientMappingSelected,
      attachments: attachment.attachments
    };

    const ticketInfo = {
      ticketInfo: values
    };

    if (clientMappingSelected && summary) {
      axios({
        url: 'create_new_ticket',
        baseURL: ENTRYPOINT,
        data: JSON.stringify(ticketInfo),
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'POST'
      }).then(() => {
        handleReload();
        hideModal();
      });
    } else {
      !summary.length > 0
        ? setOnInvalidSummary(true)
        : setOnInvalidSummary(false);
      !clientMappingSelected > 0
        ? setOnInvalidClientMapping(true)
        : setOnInvalidClientMapping(false);
    }
  };

  const handleChangeClientMapping = event => {
    setClientMapping(event.target.value);
    !event.target.value > 0
      ? setOnInvalidClientMapping(true)
      : setOnInvalidClientMapping(false);
  };

  const handleChangeSummary = event => {
    setSummary(event.target.value);
    !event.target.value.length > 0
      ? setOnInvalidSummary(true)
      : setOnInvalidSummary(false);
  };

  const handleChangeDescritption = event => {
    setDescription(event.target.value);
  };

  const handleChangeAttachment = attachmentList => {
    const attachments = { attachments: attachmentList };
    setAttachment(attachments);
  };

  return (
    <ModalContentWrapper>
      <ModalTitle>Create a Ticket</ModalTitle>
      <ModalContent>
        <ModalCreateTicket>
          <FormCreateContainer className="form-create-container needs-validation">
            <ClientMappingSelectContainer
              className="form-group"
              isHideClientMapping={isHideClientMapping}
              onInvalidClientMapping={onInvalidClientMapping}
            >
              <label
                htmlFor="ticket_clientmapping"
                className="form-control-label"
              >
                Account
              </label>
              <select
                name="clientMapping"
                id="ticket_clientmapping"
                className="form-control"
                required=""
                onChange={handleChangeClientMapping}
              >
                <option></option>
                {clientMappings.map(clientMapping => {
                  return (
                    <option
                      key={clientMapping.projectId}
                      value={clientMapping.id}
                    >
                      {clientMapping.customer.customerName}
                    </option>
                  );
                })}
              </select>
            </ClientMappingSelectContainer>

            <div className="form-group">
              <label htmlFor="ticket_summary" className="form-control-label">
                Summary
              </label>
              <SummaryInput
                name="summary"
                id="ticket_summary"
                className="form-control"
                type="text"
                placeholder=""
                onInvalidSummary={onInvalidSummary}
                value={summary}
                onChange={handleChangeSummary}
              />
              <div className="invalid-feedback">
                Please provide a valid zip.
              </div>
            </div>

            <div className="form-group">
              <label
                htmlFor="ticket_description"
                className="form-control-label"
              >
                Description
              </label>
              <textarea
                name="description"
                id="ticket_description"
                className="form-control"
                cols="5"
                placeholder=""
                value={description}
                onChange={handleChangeDescritption}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="ticket_attachments"
                className="form-control-label"
              >
                Attachment
              </label>
              <UploadFile
                handleChangeAttachment={handleChangeAttachment}
                ticket={attachment}
              />
            </div>
          </FormCreateContainer>

          <Buttons>
            <SaveChangesButton
              type="submit"
              value={submitSent ? 'Creating...' : 'Create'}
              className={'btn ' + (!submitSent ? 'active' : 'inactive')}
              onClick={handleSubmitChange}
            />
            <CancelButton type="button" value="Cancel" onClick={hideModal} />
          </Buttons>
        </ModalCreateTicket>
      </ModalContent>
    </ModalContentWrapper>
  );
};

export default CreateTicket;
