import React, { useState, useEffect } from 'react';
import { ENTRYPOINT } from '../../config/entrypoint';
import axios from 'axios/index';
import { getToken, logout, getUserId } from '../../utils/auth';
import Paginator from 'react-hooks-paginator';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, Route } from 'react-router';

const useStyles = makeStyles(() => ({
  WorklogContainer: {
    background: '#fff',
    margin: '10px',
    height: 'calc(100vh - 130px)',
    borderRadius: '5px',
    overflowY: 'auto'
  },

  WorklogTable: {
    width: '100%',
    color: '#212529',

    '& thead': {
      background: '#ECECEC'
    },

    '& tbody': {
      background: '#fff'
    },

    '& th, & td': {
      padding: '10px'
    },
    '& td': {
      borderBottom: '1px solid #dee2e6'
    }
  },
  Hours: {
    width: '10%'
  },
  Account: {
    width: '15%'
  },
  Summary: {
    width: '75%'
  },
  Action: {
    width: '5%',
    textAlign: 'center'
  },
  NoWorklog: {
    textAlign: 'center',
    padding: '40px',
    fontSize: '20px',
    color: 'red',
    fontWeight: 'bold'
  }
}));

const WorklogList = () => {
  const [isReload, setIsReload] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);

  const pageLimit = 10;
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (getUserId()) {
      axios({
        url: 'get_worklogs_list',
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          let wls = response.data;
          setData(wls);
          setIsLoading(false);
        })
        .catch(e => {
          setErrorRequest(true);
          setIsLoading(false);
        });
    }
  }, [isReload]);

  useEffect(() => {
    setCurrentData(data.slice(offset, offset + pageLimit));
  }, [offset, data]);

  const classes = useStyles();
  return (
    <div>
      {currentData.length > 0 && (
        <div className={classes.WorklogContainer}>
          <table className={classes.WorklogTable}>
            <thead className="">
              <tr>
                <th className={classes.Summary}>Summary</th>
                <th className={classes.Account}>Account</th>
                <th className={classes.Hours}>Hours</th>
              </tr>
            </thead>
            <tbody>
              <>
                {currentData.map(item => (
                  <tr key={item['id']}>
                    <td>{item['issueSummary']}</td>
                    <td>{item['employeeName']}</td>
                    <td>{item['hours']}</td>
                  </tr>
                ))}
              </>
            </tbody>
          </table>
        </div>
      )}
      {!isLoading && !data.length > 0 && (
        <div className={classes.NoWorklog}>You have no worklog.</div>
      )}

      {data.length > 0 && (
        <Paginator
          totalRecords={data.length}
          pageLimit={pageLimit}
          pageNeighbours={2}
          setOffset={setOffset}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default WorklogList;
