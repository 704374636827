import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { ENTRYPOINT, BACKEND_URL } from '../../config/entrypoint';
import axios from 'axios/index';
import LinearProgressComponent from '../StatelessComponents/LinearProgressComponent';
import InvoiceAction from './InvoiceAction';
import PaginateComponent from '../../utils/paginateComponent';
import { makeStyles } from '@material-ui/core/styles';
import { getUserId, logout, getToken } from '../../utils/auth';

const useStyles = makeStyles(() => ({
  InvoicesContainer: {
    background: '#fff',
    margin: '10px',
    height: 'calc(100vh - 114px)',
    borderRadius: '5px',
    overflowY: 'auto'
  },

  CreateFilterContainer: {
    margin: '15px'
  },

  FilterInvoicesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  FilterBySummaryContainer: {
    width: '65%',
    paddingRight: '15px',
    '& input': {
      width: '100%',
      padding: '11.5px;',
      borderRadius: '3px',
      background: '#ECECEC',
      border: 'unset'
    }
  },
  FilterSelectBox: {
    width: '20%',

    '& select': {
      display: 'block',
      width: '100%',
      height: 'calc(2.8rem + 2px)',
      fontSize: '0.8rem',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#ECECEC',
      backgroundClip: 'padding-box',
      border: '1px solid #dcdcdc',
      borderRadius: '4px',
      padding: '10px'
    }
  },
  InvoicesBlock: {
    margin: '15px'
  },
  InvoicesTable: {
    width: '100%',
    color: '#212529',

    '& thead': {
      background: '#ECECEC'
    },

    '& tbody': {
      background: '#fff'
    },

    '& th, & td': {
      padding: '10px'
    },
    '& td': {
      borderBottom: '1px solid #dee2e6'
    }
  },
  InvoiceNumber: {
    width: '10%'
  },
  Term: {
    width: '10%'
  },
  InvoiceDate: {
    width: '10%'
  },
  DueDate: {
    width: '10%'
  },
  Memo: {
    width: '20%'
  },
  TotalAmount: {
    width: '15%'
  },
  Status: {
    width: '5%'
  },
  Action: {
    width: '5%',
    textAlign: 'center'
  },
  CurrentStatus: {
    '& .new': {
      background: '#ff851b',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .paid': {
      background: '#1c7430',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .overdue': {
      background: '#F95700',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    }
  },
  EmptyInvoices: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 44px)',
    fontWeight: 'bold'
  },
  TitleEmptyInvoices: {
    color: '#41464A'
  }
}));

const InvoiceList = () => {
  const [invoices, setInvoices] = useState('');
  const [isReload, setIsReload] = useState(false);
  const [filterKey, setFilterKey] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pagerPagination, setPagerPagination] = useState(1);
  const [filterBy, setFilterBy] = useState('all');
  const [totalInvoicesCount, setTotalInvoicesCount] = useState(0);

  useEffect(() => {
    getTotalInvoicesCount(filterBy);
    getInvoiceList(filterBy, pagerPagination);
  }, [isReload]);

  const filterList = event => {
    setFilterKey(event.target.value.toLowerCase());
  };

  const downloadInvoicePdf = invoiceId => {
    if (getUserId()) {
      setIsLoading(true);
      axios({
        url: 'download_invoice_pdf/' + invoiceId,
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          let path = response.data;
          let pathDownload = path.replace(/^.*[\\\/]/, '');
          let urlPdfDownload =
            BACKEND_URL +
            'file-uploader?file_path=/invoice/pdf/' +
            invoiceId +
            '/' +
            pathDownload +
            '&type=download';
          window.open(urlPdfDownload, '_blank');
        })
        .catch(e => {})
        .finally(() => setIsLoading(false));
    }
  };

  const handleChangeFilterInvoices = event => {
    setFilterBy(event.target.value);
    getTotalInvoicesCount(event.target.value);
    getInvoiceList(event.target.value, pagerPagination);
  };

  const getInvoiceList = (filter, pagerPagination) => {
    if (getUserId()) {
      setIsLoading(true);
      axios({
        url: 'get_invoice_list/' + pagerPagination + '/' + filter,
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          setInvoices(response.data);
        })
        .catch(e => {})
        .finally(() => setIsLoading(false));
    }
  };

  const getTotalInvoicesCount = filter => {
    if (getUserId()) {
      setIsLoading(true);
      axios({
        url: 'get_total_invoices_count/' + filter,
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          setTotalInvoicesCount(response.data);
        })
        .catch(e => {});
    }
  };

  const handlePageChange = currentPage => {
    getInvoiceList(filterBy, currentPage + 1);
  };
  const classes = useStyles();
  return (
    <div>
      {isLoading && <LinearProgressComponent />}

      <div className={classes.InvoicesContainer}>
        <div className={classes.CreateFilterContainer}>
          <div className={classes.FilterInvoicesContainer}>
            <div className={classes.FilterBySummaryContainer}>
              <input
                type="text"
                placeholder="Filter by Invoice #"
                onChange={filterList}
              />
            </div>
            <div className={classes.FilterSelectBox}>
              <select onChange={handleChangeFilterInvoices}>
                <option value="all">All Invoices</option>
                <option value="paid">Paid Invoices</option>
                <option value="unpaid">Unpaid Invoices</option>
              </select>
            </div>
          </div>
        </div>
        {invoices.length > 0 && (
          <div className={classes.InvoicesBlock}>
            <table className={classes.InvoicesTable}>
              <thead>
                <tr>
                  <th className={classes.InvoiceNumber}>Invoice #</th>
                  <th className={classes.Term}>Terms</th>
                  <th className={classes.InvoiceDate}>Invoice Date</th>
                  <th className={classes.DueDate}>Due Date</th>
                  <th className={classes.Memo}>Memo</th>
                  <th className={classes.TotalAmount}>Total Amount</th>
                  <th className={classes.Status}>Status</th>
                  <th className={classes.Action}></th>
                </tr>
              </thead>
              <tbody>
                <>
                  {invoices
                    .filter(
                      item =>
                        item['docNumber'].indexOf(filterKey.toLowerCase()) !==
                        -1
                    )
                    .map(item => (
                      <tr key={item['id']}>
                        <td>#{item['docNumber']}</td>
                        <td>{item['termName']}</td>
                        <td>{item['txnDate']}</td>
                        <td>{item['dueDate']}</td>
                        <td>{item['customerMemo']}</td>
                        <td>{item['totalAmount']}</td>
                        <td>
                          <div className={classes.CurrentStatus}>
                            <div className={item['status']}>
                              {item['status']}
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <InvoiceAction
                              invoiceId={item['id']}
                              canPayInvoice={item['canPayInvoice']}
                              downloadInvoicePdf={downloadInvoicePdf}
                              invoiceLink={item['invoiceLink']}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                </>
              </tbody>
            </table>
          </div>
        )}
        {!invoices.length > 0 && (
          <div className={classes.EmptyInvoices}>
            {!getToken() && (
              <Route render={() => <Redirect from="/" to="/login/" />} />
            )}
            <div className={classes.TitleEmptyInvoices}>
              <h2>Your invoice is empty.</h2>
            </div>
          </div>
        )}
      </div>
      {invoices.length > 0 && (
        <PaginateComponent
          totalItemsCount={totalInvoicesCount}
          handlePageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default InvoiceList;
