import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router';
import { ENTRYPOINT } from '../../config/entrypoint';
import TicketAction from './TicketAction';
import { Modal, ModalStyles } from './Modal';
import CreateTicket from './Modal/tickets/CreateTicket';
import { fetch } from '../../utils/dataAccess';
import ArchiveTicket from './Modal/tickets/ArchiveTicket';
import UnArchiveTicket from './Modal/tickets/UnArchiveTicket';
import EditTicket from './Modal/tickets/EditTicket';
import DeleteTicket from './Modal/tickets/DeleteTicket';
import axios from 'axios/index';
import ViewTicket from './Modal/tickets/ViewTicket';
import { getToken, logout, getUserId } from '../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  TicketsContainer: {
    background: '#fff',
    margin: '10px',
    height: 'calc(100vh - 72px)',
    borderRadius: '5px',
    overflowY: 'auto'
  },

  CreateFilterContainer: {
    margin: '15px'
  },

  FilterTicketsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  CreateTicketContainer: {
    width: '15%',
    '& button': {
      background: '#FF6A00',
      border: 'unset',
      boxShadow: 'unset',
      margin: 'unset',
      color: '#fff'
    }
  },

  FilterBySummaryContainer: {
    width: '65%',
    paddingRight: '15px',
    '& input': {
      width: '100%',
      padding: '11.5px;',
      borderRadius: '3px',
      background: '#ECECEC',
      border: 'unset'
    }
  },
  FilterSelectBox: {
    width: '20%',

    '& select': {
      display: 'block',
      width: '100%',
      height: 'calc(2.8rem + 2px)',
      fontSize: '0.8rem',
      lineHeight: '1.5',
      color: '#495057',
      backgroundColor: '#ECECEC',
      backgroundClip: 'padding-box',
      border: '1px solid #dcdcdc',
      borderRadius: '4px',
      padding: '10px'
    }
  },
  TicketsBlock: {
    margin: '15px'
  },
  TicketsTable: {
    width: '100%',
    color: '#212529',

    '& thead': {
      background: '#ECECEC'
    },

    '& tbody': {
      background: '#fff'
    },

    '& th, & td': {
      padding: '10px'
    },
    '& td': {
      borderBottom: '1px solid #dee2e6'
    }
  },
  CheckboxItemAll: {
    width: '5%',
    textAlign: 'center',
    '& input': {
      width: '20px',
      height: '20px',
      paddingTop: '10px'
    }
  },

  Summary: {
    width: '45%'
  },
  Account: {
    width: '35%'
  },

  Status: {
    width: '10%'
  },
  Action: {
    width: '5%',
    textAlign: 'center'
  },
  CurrentStatus: {
    '& .new': {
      background: '#ff851b',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .pending': {
      background: '#0b6a74',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .inprogress': {
      background: '#744c09',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .done': {
      background: '#1c7430',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .archive': {
      background: '#690674',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    }
  },
  SummaryContent: {
    cursor: 'pointer'
  },
  EmptyTickets: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: 'calc(100vh - 44px)',
    fontWeight: 'bold',
    '& button': {
      background: '#FF6A00',
      border: 'unset',
      boxShadow: 'unset',
      margin: 'unset',
      color: '#fff'
    }
  },
  TitleEmptyTickets: {
    color: '#41464A'
  },
  Title: {
    color: '#979797'
  }
}));

const TicketList = ({ isOpenCreateTicketModal }) => {
  const [errorRequest, setErrorRequest] = useState(false);
  const [tickets, setTickets] = useState('');
  const [ticketsArchive, setTicketsArchive] = useState('');
  const [clientMappings, setClientMappings] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalStyle, setModalStyle] = useState(null);
  const [modalFading, setModalFading] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [isReload, setIsReload] = useState(false);
  const [filterKey, setFilterKey] = useState('');
  const [filterTicketArchive, setFilterTicketArchive] = useState(false);
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState('ticket');

  function hideModal() {
    setTimeout(() => {
      setModalShow(false);
      setModalStyle(null);
      setModalFading(false);
      setModalContent('');
    }, 300);
  }

  useEffect(() => {
    if (isOpenCreateTicketModal) {
      createNewTicket();
    }
  }, [isOpenCreateTicketModal]);

  useEffect(() => {
    if (getUserId()) {
      axios({
        url: 'get_ticket_list',
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          let ticketNormal = response.data.filter(
            ticket => ticket.ticketStatus !== 'Archive'
          );
          let ticketArchive = response.data.filter(
            ticketArchive => ticketArchive.ticketStatus === 'Archive'
          );
          setTickets(ticketNormal);
          setTicketsArchive(ticketArchive);
        })
        .catch(e => {
          setErrorRequest(true);
          logout();
        });

      fetch('client_mapping?id=' + getUserId())
        .then(response => {
          response.json().then(json => {
            setClientMappings(json['hydra:member']);
          });
        })
        .catch(e => {
          setErrorRequest(true);
          logout();
        });
    }
  }, [isReload]);

  const filterList = event => {
    setFilterKey(event.target.value.toLowerCase());
    setCurrentSelectedFilter('ticket');
  };

  const handleFilterTicketArchive = event => {
    setFilterTicketArchive(event.target.value === 'ticket-archive');
    setCurrentSelectedFilter('ticket-archive');
  };

  const createNewTicket = () => {
    if (clientMappings.length > 0) {
      setModalShow(true);
      setModalStyle(ModalStyles.CONFIRMATION);
      setModalFading(false);
      setModalContent(
        <CreateTicket
          handleReload={handleReload}
          clientMappings={clientMappings}
          hideModal={hideModal}
        />
      );

      return;
    }

    fetch('client_mapping?id=' + getUserId())
      .then(response => {
        response.json().then(json => {
          setClientMappings(json['hydra:member']);
          // load the modal with the request result immediately instead of waiting for the state to update
          setModalShow(true);
          setModalStyle(ModalStyles.CONFIRMATION);
          setModalFading(false);
          setModalContent(
            <CreateTicket
              handleReload={handleReload}
              clientMappings={json['hydra:member']}
              hideModal={hideModal}
            />
          );
        });
      })
      .catch(e => {
        // load the modal anyway
        setModalShow(true);
        setModalStyle(ModalStyles.CONFIRMATION);
        setModalFading(false);
        setModalContent(
          <CreateTicket
            handleReload={handleReload}
            clientMappings={clientMappings}
            hideModal={hideModal}
          />
        );
      });
  };

  const archiveTicket = ticketId => {
    setModalShow(true);
    setModalStyle(ModalStyles.CONFIRMATION);
    setModalFading(false);
    setModalContent(
      <ArchiveTicket
        ticketId={ticketId}
        handleReload={handleReload}
        hideModal={hideModal}
      />
    );
  };

  const unArchiveTicket = ticketId => {
    setModalShow(true);
    setModalStyle(ModalStyles.CONFIRMATION);
    setModalFading(false);
    setModalContent(
      <UnArchiveTicket
        ticketId={ticketId}
        handleReload={handleReload}
        hideModal={hideModal}
      />
    );
  };

  const deleteTicket = ticketId => {
    setModalShow(true);
    setModalStyle(ModalStyles.CONFIRMATION);
    setModalFading(false);
    setModalContent(
      <DeleteTicket
        ticketId={ticketId}
        handleReload={handleReload}
        hideModal={hideModal}
      />
    );
  };

  const editTicket = ticketId => {
    setModalShow(true);
    setModalStyle(ModalStyles.CONFIRMATION);
    setModalFading(false);
    setModalContent(
      <EditTicket
        ticketId={ticketId}
        handleReload={handleReload}
        clientMappings={clientMappings}
        hideModal={hideModal}
      />
    );
  };

  const viewTicket = ticketId => {
    setModalShow(true);
    setModalStyle(ModalStyles.CONFIRMATION);
    setModalFading(false);
    setModalContent(
      <ViewTicket
        ticketId={ticketId}
        handleReload={handleReload}
        clientMappings={clientMappings}
        hideModal={hideModal}
      />
    );
  };

  const handleReload = () => {
    setIsReload(!isReload);
  };

  const classes = useStyles();

  return (
    <div>
      {errorRequest && (
        <Route render={() => <Redirect from="/" to="/login/" />} />
      )}
      <div>
        <Modal
          show={modalShow}
          modalStyle={modalStyle}
          modalFading={modalFading}
          hideModal={hideModal}
        >
          {modalContent}
        </Modal>
      </div>
      {(tickets.length > 0 || ticketsArchive.length > 0) && (
        <div className={classes.TicketsContainer}>
          <div className={classes.CreateFilterContainer}>
            <div className={classes.FilterTicketsContainer}>
              <div className={classes.CreateTicketContainer}>
                <div>
                  <button
                    type="button"
                    className="btn"
                    onClick={createNewTicket}
                  >
                    Create Ticket
                  </button>
                </div>
              </div>
              <div className={classes.FilterBySummaryContainer}>
                <input
                  type="text"
                  placeholder="Filter by Summary"
                  onChange={filterList}
                />
              </div>
              <div className={classes.FilterSelectBox}>
                <select onChange={handleFilterTicketArchive}>
                  <option
                    value="ticket"
                    selected={currentSelectedFilter === 'ticket'}
                  >
                    Active Tickets
                  </option>
                  <option
                    value="ticket-archive"
                    selected={currentSelectedFilter === 'ticket-archive'}
                  >
                    Archived Tickets
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className={classes.TicketsBlock}>
            <table className={classes.TicketsTable}>
              <thead className="">
                <tr>
                  <th className={classes.CheckboxItemAll}>
                    <input type="checkbox" />
                  </th>
                  <th className={classes.Summary}>Summary</th>
                  <th className={classes.Account}>Account</th>
                  <th className={classes.Status}>Status</th>
                  <th className={classes.Action}>
                    <i className="fas fa-ellipsis-v"></i>
                  </th>
                </tr>
              </thead>
              <tbody>
                {!filterTicketArchive && (
                  <>
                    {tickets
                      .filter(
                        item =>
                          item['summary']
                            .toLowerCase()
                            .indexOf(filterKey.toLowerCase()) !== -1
                      )
                      .map(item => (
                        <tr key={item['id']}>
                          <td className={classes.CheckboxItemAll}>
                            <input type="checkbox" />
                          </td>
                          <td
                            className={classes.SummaryContent}
                            onClick={() => viewTicket(item['id'])}
                          >
                            {item['summary']}
                          </td>
                          <td>{item['clientMapping']}</td>
                          <td>
                            <div className={classes.CurrentStatus}>
                              <div className={item['ticketStatusSlug']}>
                                {item['ticketStatus']}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row-actions">
                              {/* this "row-actions" class is used for automated tests */}
                              <TicketAction
                                ticketId={item['id']}
                                archiveTicket={archiveTicket}
                                unArchiveTicket={unArchiveTicket}
                                editTicket={editTicket}
                                deleteTicket={deleteTicket}
                                filterTicketArchive={filterTicketArchive}
                                canEdit={item['canEdit']}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </>
                )}

                {filterTicketArchive && (
                  <>
                    {ticketsArchive
                      .filter(
                        item =>
                          item['summary']
                            .toLowerCase()
                            .indexOf(filterKey.toLowerCase()) !== -1
                      )
                      .map(item => (
                        <tr key={item['id']}>
                          <td className={classes.CheckboxItemAll}>
                            <input type="checkbox" />
                          </td>
                          <td>{item['summary']}</td>
                          <td>{item['clientMapping']}</td>
                          <td>
                            <div className={classes.CurrentStatus}>
                              <div className={item['ticketStatusSlug']}>
                                {item['ticketStatus']}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="row-actions">
                              {/* this "row-actions" class is used for automated tests */}
                              <TicketAction
                                ticketId={item['id']}
                                archiveTicket={archiveTicket}
                                unArchiveTicket={unArchiveTicket}
                                editTicket={editTicket}
                                deleteTicket={deleteTicket}
                                filterTicketArchive={filterTicketArchive}
                                canEdit={item['canEdit']}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {!tickets.length > 0 && !ticketsArchive.length > 0 && (
        <div className={classes.EmptyTickets}>
          {!getToken() && (
            <Route render={() => <Redirect from="/" to="/login/" />} />
          )}
          <div className={classes.TitleEmptyTickets}>You have no tickets.</div>
          <div className={classes.Title}>
            To report an issue, or request a new feature
          </div>
          <div className={classes.Title}>create a ticket.</div>
          <button type="button" className="btn" onClick={createNewTicket}>
            Create Ticket
          </button>
        </div>
      )}
    </div>
  );
};

export default TicketList;
