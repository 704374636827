import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { reducer as form } from 'redux-form';
import { Route, Switch, Redirect } from 'react-router';
import { createBrowserHistory } from 'history';
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware
} from 'connected-react-router';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import * as serviceWorker from './serviceWorker';
import './index.css';

// Replace "book" with the name of the resource type

import loginRoute from './routes/login';
import dashboardRoute from './routes/dashboard';
import worklogsRoute from './routes/worklogs';
import ticketsRoute from './routes/tickets';
import invoicesRoute from './routes/invoices';
import accountRoute from './routes/account';
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  createGenerateClassName,
  responsiveFontSizes
} from '@material-ui/core/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'et-'
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
    secondary: {
      main: '#f2672e'
    }
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: '13px'
      }
    }
  }
});
const history = createBrowserHistory();
const store = createStore(
  combineReducers({
    router: connectRouter(history),
    form
  }),
  applyMiddleware(routerMiddleware(history), thunk)
);
ReactDOM.render(
  <StylesProvider generateClassName={generateClassName}>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <Switch>
            {loginRoute}
            {dashboardRoute}
            {worklogsRoute}
            {ticketsRoute}
            {invoicesRoute}
            {accountRoute}
            <Route render={() => <Redirect from="/" to="/login/" />} />
          </Switch>
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </StylesProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
