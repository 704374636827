import React from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    position: 'absolute',
    width: '100%'
  }
});

export function LinearLoaderComponent() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
}
