import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { isEmployee } from '../../utils/auth';

const PanelLeftContent = styled.div`
  .nav-dashboard {
    background: #ff6a00;
  }

  .nav-tickets {
    background: #ffa60c;
  }

  .nav-work-logs {
    background: #56248f;
  }

  .nav-invoices {
    background: #206f21;
  }
`;
const TicketIcon = styled.div`
  display: flex;
  justify-content: center;
  align-item: center;
  cursor: pointer;

  i {
    padding: 15px;
    color: #fff;
  }
`;

const SideBarLeft = () => {
  const isEmployeeUser = isEmployee();
  return (
    <PanelLeftContent>
      <TicketIcon className="nav-dashboard" title="Dashboard">
        <Link to="/dashboard">
          <i className="fas fa-tachometer-alt"></i>
        </Link>
      </TicketIcon>

      {!isEmployeeUser && (
        <TicketIcon className="nav-tickets" title="Tickets">
          <Link to="/tickets">
            <i className="fas fa-archive"></i>
          </Link>
        </TicketIcon>
      )}

      <TicketIcon className="nav-work-logs" title="Work Logs">
        <Link to="/worklogs">
          <i className="fas fa-briefcase"></i>
        </Link>
      </TicketIcon>

      {!isEmployeeUser && (
        <TicketIcon className="nav-invoices" title="Invoices">
          <Link to="/invoices">
            <i className="fas fa-file-invoice-dollar"></i>
          </Link>
        </TicketIcon>
      )}
    </PanelLeftContent>
  );
};

export default SideBarLeft;
