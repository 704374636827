import React, { useState, useEffect } from 'react';
import { GITLAB_PRIVATE_TOKEN } from '../../config/entrypoint.js';
import { VictoryChart, VictoryAxis, VictoryBar, VictoryLabel } from 'victory';
import { Container, useMediaQuery, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getUsername } from '../../utils/auth';

const CommitChart = () => {
  const [data, setData] = useState({
    empty: 'Empty array'
  });
  const useStyles = makeStyles({
    victoryContainer: {
      backgroundColor: '#ffffff',
      marginTop: '5px'
    },
    progressBar: {
      textAlign: 'center',
      padding: '50px'
    },
    progressCircle: {
      color: '#FF6A00'
    }
  });
  const maxWidthCheck = useMediaQuery('(max-width:767px');
  useEffect(() => {
    const fetchData = async () => {
      let oneDayAsync = 24 * 60 * 60 * 1000;
      let today = new Date(Date.now());
      let beforeParam = new Date(today.getTime() + oneDayAsync);
      beforeParam = `${beforeParam.toLocaleDateString('en-ZA', {
        dateStyle: 'short'
      })}`;
      let preformattedParam = new Date(
        today.getTime() - 24 * 60 * 60 * 1000 * 6
      );
      preformattedParam.setHours(0);
      preformattedParam.setMinutes(0);
      preformattedParam.setSeconds(0);
      let afterParam = `${preformattedParam.toLocaleDateString('en-ZA', {
        dateStyle: 'short'
      })}`;

      const result = await fetch(
        `https://gitlab.endertech.net/api/v4/users/${getUsername()}/events?private_token=${GITLAB_PRIVATE_TOKEN}&action=pushed&before=${beforeParam}&after=${afterParam}`,
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }
      )
        .then(x => x.json())
        .then(data => {
          if (data) {
            return data;
          }
          return 'EMPTY';
        })
        .catch(e => console.log(e));

      setData(result);
    };
    fetchData();
  }, []);

  const classes = useStyles();
  let oneDay = 24 * 60 * 60 * 1000;
  let today = Date.now();
  let todayDate = new Date(today);
  let prevWeek = new Date(todayDate.getTime() - oneDay * 6);
  let tickVals = [5, 10, 15, 20];
  let max = 20;

  //set the start of last week at 00:00:00 time
  prevWeek.setHours(0);
  prevWeek.setMinutes(0);
  prevWeek.setSeconds(0);
  prevWeek.setMilliseconds(0);

  let dataHold = [];
  let week = {};
  for (let x = 0; x < 7; x++) {
    let thisDay = prevWeek.toLocaleDateString();
    week[thisDay] = 0;
    prevWeek.setDate(prevWeek.getDate() + 1);
  }

  if (!data['empty'])
    for (let x in data) {
      let retrieved = data[x]['created_at'];
      let retrievedDate = new Date(retrieved);
      week[retrievedDate.toLocaleDateString()] +=
        data[x]['push_data']['commit_count'];
    }

  for (let x = 0; x < 7; x++) {
    let timeVal = week[Object.keys(week)[x]];
    let current = Object.keys(week)[x];
    let shortenedDate = current.slice(0, current.length - 5);
    if (timeVal > max) {
      max = timeVal;
    }
    dataHold.push({ day: shortenedDate, time: timeVal });
  }

  if (max > 20)
    for (let i = 0; i < max + 10; i += 10) {
      tickVals[i / 10] = i;
    }

  const Content = () => {
    if (data) {
      return (
        <VictoryChart
          domainPadding={{ x: 15 }}
          padding={30}
          style={{ data: { fill: '#FF6A00' } }}
        >
          <VictoryLabel
            text={`Pushed Commits Chart`}
            textAnchor="middle"
            x={230}
            y={15}
          />
          <VictoryBar
            data={dataHold}
            x="day"
            y="time"
            labels={x => x.datum.time}
            style={{
              labels: { fill: 'black' },
              data: { fill: '#FF6A00' }
            }}
          ></VictoryBar>
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: maxWidthCheck ? 16 : 14
              },
              axis: { strokeWidth: 0.1 }
            }}
          ></VictoryAxis>
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: {
                fontSize: maxWidthCheck ? 16 : 14
              },
              axis: { strokeWidth: 0.1 }
            }}
            tickValues={tickVals}
          ></VictoryAxis>
        </VictoryChart>
      );
    } else {
      return (
        <div className={classes.progressBar}>
          <CircularProgress
            size="65%"
            classes={{
              colorPrimary: classes.progressCircle
            }}
          />
        </div>
      );
    }
  };
  return (
    <Container className={classes.victoryContainer}>
      <Content></Content>
    </Container>
  );
};

export default CommitChart;
