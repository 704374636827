import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginateContainer = styled.div`
  text-align: center;

  .pagination {
    display: inline-block;
    padding-left: 0;
    margin: 0;
    border-radius: 4px;

    .disabled {
      a {
        color: #777777;
        background-color: #fff;
        border-color: #ddd;
        cursor: not-allowed;
      }
    }

    .active {
      a {
        z-index: 3;
        color: #fff;
        background-color: #337ab7;
        border-color: #337ab7;
        cursor: default;
      }
    }

    li {
      display: inline;
      a {
        position: relative;
        float: left;
        padding: 6px 12px;
        line-height: 1.42857143;
        text-decoration: none;
        color: #337ab7;
        background-color: #fff;
        border: 1px solid #ddd;
        margin-left: -1px;
      }
    }
  }
`;

const paginateComponent = ({ handlePageChange, totalItemsCount }) => {
  const handlePageClickChange = data => {
    handlePageChange(data.selected);
  };

  return (
    <PaginateContainer>
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={Math.ceil(totalItemsCount / 10)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={10}
        onPageChange={handlePageClickChange}
        containerClassName={'pagination'}
        subContainerClassName={'pages pagination'}
        activeClassName={'active'}
        disabledClassName={'disabled'}
      />
    </PaginateContainer>
  );
};

export default paginateComponent;
