import React from 'react';

import TopContentRight from '../template/TopContentRight';
import WorklogList from '../worklogs/WorklogList';

const WorkLogsContent = () => {
  return (
    <>
      <TopContentRight title="Worklogs" />
      <WorklogList />
    </>
  );
};

export default WorkLogsContent;
