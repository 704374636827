import React, { useEffect, useState } from 'react';
import axios from 'axios/index';
import { getToken, getUserId, logout } from '../../../../utils/auth';
import { ENTRYPOINT } from '../../../../config/entrypoint';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginTop: '5px'
  },
  padding: {
    '&:last-child': {
      paddingBottom: '16px'
    }
  },
  title: {
    fontSize: 14
  }
});

const TotalStoryPoint = () => {
  const [totalStoryPoint, setTotalStoryPoint] = useState('loading...');
  useEffect(() => {
    if (getUserId()) {
      axios({
        url: 'get_sum_story_point',
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          setTotalStoryPoint(
            'Sum of story points in the past 7 days: ' +
              response.data +
              ' hours'
          );
        })
        .catch(e => {});
    }
  }, []);
  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.padding}>
        <Typography variant="h5" component="h2" align="center">
          {totalStoryPoint}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TotalStoryPoint;
