import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { PrivateRoute } from './PrivateRoute';
import logoEndertech from '../css/images/general/endertech-icon.png';
import SideBarLeft from './template/SideBarLeft';

const useStyles = makeStyles(() => ({
  DashboardContainer: {
    margin: '0 auto',
    display: 'flex'
  },
  PanelLeft: {
    width: '50px',
    background: '#D8D8D8'
  },
  PanelLeftLogo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    '& img': {
      width: '30px'
    }
  },
  PanelRight: {
    width: 'calc(100% - 50px)',
    background: '#ccc'
  }
}));

export const PrivateLayout = ({ component: Component, ...rest }) => {
  const classes = useStyles();
  return (
    <PrivateRoute
      {...rest}
      component={matchProps => (
        <Container className={classes.DashboardContainer}>
          <div className={classes.PanelLeft}>
            <div className={classes.PanelLeftContainer}>
              <div className={classes.PanelLeftLogoContainer}>
                <div className={classes.PanelLeftLogo}>
                  <img src={logoEndertech} />
                </div>
              </div>
              <SideBarLeft />
            </div>
          </div>
          <div className={classes.PanelRight}>
            <Component {...matchProps} />
          </div>
        </Container>
      )}
    />
  );
};
