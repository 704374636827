function decode(token) {
  if (typeof token === undefined || token === 'undefined' || !token) {
    return false;
  }
  var base64Url = token.split('.')[1];
  var base64 = decodeURIComponent(
    atob(base64Url)
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(base64);
}

export function getToken() {
  return localStorage.getItem('token');
}

export function getProfile() {
  return decode(getToken());
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    if (decoded.exp < Date.now() / 1000) {
      logout();
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export function login(token) {
  localStorage.setItem('token', token);
}

export function isLoggedIn() {
  const token = getToken();
  return !!token && !isTokenExpired(token);
}

export function logout() {
  localStorage.removeItem('token');
}

export function logoutIfUnauthorized(response, json, error) {
  if (
    (response.status === 401 || error === 'Unauthorized') &&
    json.message &&
    (json.message.indexOf('JWT') !== -1 ||
      json.message.indexOf('Expired') !== -1 ||
      json.message.indexOf('Unable') !== -1 ||
      json.message.indexOf('renew') !== -1 ||
      json.message.indexOf('verify') !== -1)
  ) {
    logout();

    localStorage.setItem('login_redirect_path', window.location.pathname);

    // redirect
    window.location.pathname = '/login';
  }
}

export function applyAuthHeader(headers) {
  let token = getToken();
  if (token) {
    headers.set('Authorization', 'Bearer ' + token);
  }
}

export function getUserId() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (profile.userid) {
    return profile.userid;
  }

  return false;
}

export function getUsername() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (profile.username) {
    return profile.username;
  }

  return false;
}

export function getUserRoles() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (profile.roles && profile.roles.length > 0) {
    return profile.roles;
  }

  return false;
}

export function isAdmin() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (
    profile.roles &&
    profile.roles.length > 0 &&
    (profile.roles.indexOf('ROLE_ADMIN') !== -1 ||
      profile.roles.indexOf('ROLE_SUPER_ADMIN') !== -1)
  ) {
    return true;
  }

  return false;
}

export function isClient() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (
    profile.roles &&
    profile.roles.length > 0 &&
    profile.roles.indexOf('ROLE_CLIENT') !== -1
  ) {
    return true;
  }

  return false;
}

export function isEmployee() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (
    profile.roles &&
    profile.roles.length > 0 &&
    profile.roles.includes('ROLE_EMPLOYEE')
  ) {
    return true;
  }

  return false;
}

export function isUser() {
  if (!isLoggedIn()) {
    return false;
  }

  const profile = getProfile();
  if (
    profile.roles &&
    profile.roles.length > 0 &&
    profile.roles.indexOf('ROLE_USER') !== -1
  ) {
    return true;
  }

  return false;
}

export function isAuthorizedUser() {
  if (!isLoggedIn()) {
    return false;
  }

  if (isAdmin() || isEmployee() || isClient()) {
    return true;
  }

  return false;
}

export function getUserType() {
  if (!isLoggedIn()) {
    return false;
  }

  if (isEmployee()) {
    return 'Employee';
  }

  if (isClient()) {
    return 'Client';
  }

  return false;
}
