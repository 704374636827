import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core/';
import TimeChartDashboardContent from './TimeChart';
import FundamentalDashboardContent from './Fundamentals';
import CompanyTimeDashboardContent from './CompanyTimeChart';
import TotalStoryPoint from '../account/employee/storyPoint/TotalStoryPoint';
import CommitChartDashboardContent from './CommitChart';
import DealCountCard from './DealCountCard';
import LeadCountCard from './LeadCountCard';

const useStyles = makeStyles({
  grid: {
    padding: '8px'
  }
});
const EmployeeDashboardContent = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignContent="center"
      classes={{ root: classes.grid }}
      spacing={1}
    >
      <Grid item xs={12} sm={12} md={8}>
        <TimeChartDashboardContent />
        <CompanyTimeDashboardContent />
        <CommitChartDashboardContent />
        <TotalStoryPoint />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <FundamentalDashboardContent />
        <DealCountCard />
        <LeadCountCard />
      </Grid>
    </Grid>
  );
};

export default EmployeeDashboardContent;
