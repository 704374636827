import React, { useState } from 'react';
import styled from 'styled-components';
import Settings from './Settings';

const PanelRightHeader = styled.div``;
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 11px 20px 11px 15px;
  background: #fff;
`;
const HeaderLeft = styled.div``;
const HeaderLeftContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const HeaderLeftIcon = styled.div`
  padding: 10px;
`;
const HeaderLeftTitle = styled.div``;
const HeaderRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TopContentRight = ({ title }) => {
  return (
    <PanelRightHeader>
      <HeaderContainer>
        <HeaderLeft>
          <HeaderLeftContainer>
            <HeaderLeftTitle>{title}</HeaderLeftTitle>
          </HeaderLeftContainer>
        </HeaderLeft>
        <HeaderRight>
          <Settings></Settings>
        </HeaderRight>
      </HeaderContainer>
    </PanelRightHeader>
  );
};

export default TopContentRight;
