import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormControl,
  FormLabel,
  ButtonToolbar
} from 'react-bootstrap';
import { Button } from '@material-ui/core';
import { BACKEND_URL } from '../../config/entrypoint';
import logoEndertech from '../../css/images/general/endertech-icon.png';
import axios from 'axios/index';
import styled from 'styled-components';
import { Route, Redirect, useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import { LinearLoaderComponent } from '../StatelessComponents';
import { login, getToken } from '../../utils/auth';

const LoginBox = styled.div`
  width: 500px;
  margin: 0 auto;
  padding-top: 7%;
`;
const LoginLogoContainer = styled.div`
  font-size: 24px;
  text-align: center;
  margin-bottom: 25px;
  font-weight: bold;
`;

const LoginLogoBox = styled.a`
  color: #41464a;
  &:hover {
    color: #41464a;
  }
`;
const LoginLogo = styled.div`
  margin-bottom: 15px;
  img {
    width: 64px;
  }
`;
const LoginLogoText = styled.div`
  font-size: 18px;
`;
const LoginContainer = styled.div``;

const LoginFormContainer = styled.div`
  background: #fff;
  padding: 30px;
  border-radius: 5px;

  button {
    margin: unset;
    margin-top: 10px;
    background: #ff6a00 !important;
  }

  .btn-primary:not([disabled]):not(.disabled):active {
    background: #ff6a00 !important;
    border-color: #ff6a00;
  }

  label {
    font-weight: bold;
    color: #a9a9aa;
  }

  .form-control {
    font-size: 0.8rem;
    background: #ececec;
  }
`;

const useStyles = makeStyles({
  button: {
    color: '#fff',
    borderRadius: 2,
    textTransform: 'capitalize'
  },
  LoginBox: {
    maxWidth: '500px',
    margin: '0 auto',
    paddingTop: '17vh'
  },
  LoginLogoContainer: {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '25px',
    fontWeight: 'bold'
  },
  LoginLogoBox: {
    color: '#41464a',
    '&:hover': {
      color: '#41464a'
    }
  },
  LoginLogoText: {
    fontSize: '18px'
  },
  LoginLogo: {
    marginBotton: '15px'
  },
  Img: {
    width: '64px'
  },
  LoginText: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  LoginFormContainer: {
    background: '#fff',
    padding: '30px',
    borderRadius: '5px'
  },
  Label: {
    fontWeight: 'bold',
    color: '#a9a9aa'
  },
  FormControl: {
    fontSize: '0.8rem',
    background: '#ececec'
  }
});

const ActivateAccount = props => {
  const [password, setPassword] = useState('');
  const [disable, setDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [tokenClient, setTokenClient] = useState('');
  const [userName, setUserName] = useState('');

  const history = useHistory();

  const handleChangePassword = event => {
    const password = event.target.value;
    setPassword(password);
  };
  useEffect(() => {
    const validateForm = () => {
      setDisable(!(password.length > 0));
    };
    validateForm();
    setTokenClient(props.match.params.tokenClient);
    setUserName(props.match.params.userName);
  }, [password]);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    var values = {
      userName: userName,
      tokenClient: tokenClient,
      password: password
    };
    const info = {
      info_activate: values
    };
    axios({
      url: 'client_activate',
      baseURL: BACKEND_URL,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(info)
    })
      .then(response => {
        if (response.status == 200) {
          var values = { username: userName, password: password };
          axios({
            url: 'login/login_check',
            baseURL: BACKEND_URL,
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(values)
          })
            .then(response => {
              if (response.status == 200) {
                login(response.data.token);
                history.push('/dashboard/');
              }
            })
            .catch(err => {
              setErrorMessage('There is an Authentication error occurred');
            })
            .finally(() => setIsLoading(false));
        }
      })
      .catch(err => {
        setErrorMessage('There is an error occurred');
      })
      .finally(() => setIsLoading(false));
  };

  const classes = useStyles();
  return (
    <>
      {isLoading && <LinearLoaderComponent />}
      <div className={classes.LoginBox}>
        {getToken() && <Route render={() => <Redirect to="/dashboard/" />} />}
        <div className={classes.LoginLogoContainer}>
          <a
            className={classes.LoginLogoBox}
            target="_blank"
            rel="noopener noreferrer"
            href="http://endertech.com"
          >
            <div className={classes.LoginLogo}>
              <img
                className={classes.Img}
                src={logoEndertech}
                alt="Endertech Logo"
              />
            </div>
            <div className={classes.LoginLogoText}>
              <span>Welcome back, to Endertech Portal.</span>
            </div>
          </a>
        </div>
        <div className="Login">
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}
          <form className={classes.LoginFormContainer} onSubmit={handleSubmit}>
            <div className={classes.LoginText}>Activate Account</div>
            <FormGroup controlId="password" bssize="large">
              <FormLabel>Password</FormLabel>
              <FormControl
                className={classes.FormControl}
                value={password}
                onChange={handleChangePassword}
                type="password"
                placeholder="Enter Password"
              />
            </FormGroup>
            <ButtonToolbar>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                disabled={disable || isLoading}
                type="submit"
                onClick={handleSubmit}
                className={classes.button}
              >
                Activate Account
              </Button>
            </ButtonToolbar>
          </form>
        </div>
      </div>
    </>
  );
};

export default ActivateAccount;
