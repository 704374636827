import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios/index';
import { ENTRYPOINT } from '../../../../config/entrypoint';
import { getToken } from '../../../../utils/auth';

// style

const fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const ModalContentWrapper = styled.div`
  animation: ${fadein} 500ms;

  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 753px;
`;

const ModalTitle = styled.div`
  background-color: #fff;
  padding: 18px 56px 5px 18px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #52595f;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
`;

const ModalContent = styled.div`
  background-color: #ffffff;

  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% - 76px);

  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  margin: 10px;
`;

const Message = styled.div`
  margin-bottom: 20px;
`;

const ArchiveButton = styled.input``;

const CancelButton = styled.input`
  border-color: #c4c4c4;
`;

// main

const UnArchiveTicket = ({ ticketId, hideModal, handleReload }) => {
  const [submitSent, setSubmitSent] = useState(false);

  const handleArchiveSubmit = event => {
    axios({
      url: 'un_archive_ticket/' + ticketId,
      baseURL: ENTRYPOINT,
      headers: { Authorization: 'Bearer ' + getToken() },
      method: 'PUT'
    }).then(() => {
      handleReload();
      hideModal();
    });
  };

  const handleCancel = () => {
    hideModal();
  };

  let enableButtons;
  let unArchiveButtonLabel;
  if (submitSent) {
    enableButtons = false;
    unArchiveButtonLabel = 'UnArchive...';
  } else {
    enableButtons = true;
    unArchiveButtonLabel = 'UnArchive';
  }

  return (
    <ModalContentWrapper>
      <ModalTitle>UnArchive a Ticket</ModalTitle>
      <ModalContent>
        <div>
          <Message>Are you sure to unArchive a ticket?</Message>
          <Buttons>
            <ArchiveButton
              type="submit"
              value={unArchiveButtonLabel}
              className={
                'btn btn-md btn-info ' + (enableButtons ? 'active' : 'inactive')
              }
              disabled={!enableButtons}
              onClick={handleArchiveSubmit}
            />
            <CancelButton
              type="button"
              value="Cancel"
              className={
                'btn btn-md ' + (enableButtons ? 'active' : 'inactive')
              }
              disabled={!enableButtons}
              onClick={handleCancel}
            />
          </Buttons>
        </div>
      </ModalContent>
    </ModalContentWrapper>
  );
};

export default UnArchiveTicket;
