import React from 'react';
import styled from 'styled-components';

// style

const Container = styled.div`
  position: relative;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  ul ul {
    display: none;
    position: absolute;
    width: 196px;
  }

  ul ul {
    top: 0;
    right: 0;
  }

  ul li:hover > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 1rem 1.5rem 1rem 1rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
  }
`;

const DropDownToggle = styled.div`
  font-size: 1.2rem;
  cursor: pointer;

  display: flex;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Link = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  :not(:last-of-type) {
    margin-bottom: 4px;
  }

  i {
    padding: 10px;
  }

  a {
    font-size: 0.9rem;
    letter-spacing: 0.01rem;
    color: #454a4e;
    transition: color 0.3s;

    &:hover {
      color: #42a8df;
      text-decoration: none;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.6rem;
    }
  }

  .fa-trash {
    color: red;
  }

  .delete-action {
    color: red;
    &:hover {
      color: red;
    }
  }
`;

// main

const TicketAction = props => {
  const {
    position,
    ticketId,
    archiveTicket,
    filterTicketArchive,
    editTicket,
    deleteTicket,
    unArchiveTicket,
    canEdit
  } = props;

  const handleArchiveTicket = () => {
    archiveTicket(ticketId);
  };

  const handleUnArchiveTicket = () => {
    unArchiveTicket(ticketId);
  };

  const handleDeleteTicket = () => {
    deleteTicket(ticketId);
  };

  const handleEditTicket = () => {
    editTicket(ticketId);
  };

  return (
    <Container className={position} onClick={event => event.stopPropagation()}>
      <ul>
        <li>
          <DropDownToggle className={position}>
            <i className="fas fa-ellipsis-v"></i>
          </DropDownToggle>
          <ul>
            {canEdit && (
              <Link>
                <i className="fas fa-edit"></i>
                <div onClick={handleEditTicket}>Edit Ticket</div>
              </Link>
            )}
            {!filterTicketArchive && (
              <Link>
                <i className="fas fa-archive"></i>
                <div onClick={handleArchiveTicket}>Archive Ticket</div>
              </Link>
            )}
            {filterTicketArchive && (
              <Link>
                <i className="fas fa-archive"></i>
                <div onClick={handleUnArchiveTicket}>UnArchive Ticket</div>
              </Link>
            )}
            <Link>
              <i className="fas fa-trash"></i>
              <div className="delete-action" onClick={handleDeleteTicket}>
                Delete Ticket
              </div>
            </Link>
          </ul>
        </li>
      </ul>
    </Container>
  );
};

export default TicketAction;
