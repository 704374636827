import React, { useState, useEffect } from 'react';
import axios from 'axios/index';
import { BACKEND_URL } from '../../config/entrypoint.js';
import {
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryBar,
  VictoryStack,
  VictoryLabel
} from 'victory';
import { Container, useMediaQuery, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getToken } from '../../utils/auth';

const TimeChart = () => {
  const [data, setData] = useState([]);
  const maxWidthCheck = useMediaQuery('(max-width:767px');
  const useStyles = makeStyles({
    victoryContainer: {
      backgroundColor: '#ffffff',
      marginTop: '5px'
    },
    progressBar: {
      textAlign: 'center',
      padding: '50px'
    },
    progressCircle: {
      color: '#FF6A00'
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      let today = new Date(Date.now());
      console.log(today.toISOString());
      const result = await axios({
        url: `/api/worklog-time-employee-expected-hours`,
        baseURL: BACKEND_URL,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken(),
          TimeZone: `${today.toISOString()}`,
          Offset: `${today.getTimezoneOffset() / 60}`
        }
      });
      setData(result['data']);
    };
    fetchData();
  }, []);

  let expected = data['expectedHours'];
  const classes = useStyles();
  let dataHold = [];
  const oneDay = 24 * 60 * 60 * 1000;
  let today = Date.now();
  let todayDate = new Date(today);
  let prevWeek = new Date(todayDate.getTime() - oneDay * 6);
  let xAxis = [];
  for (let x = 0; x < 7; x++) {
    let thisDay = prevWeek.toLocaleDateString();
    let shortenedDate = thisDay.slice(0, thisDay.length - 5);
    xAxis[x] = shortenedDate;
    prevWeek.setDate(prevWeek.getDate() + 1);
  }

  for (const day in data['days']) {
    console.log(data['days'][day]);
    dataHold.push({ day: day, time: data['days'][day] });
  }

  //used to set the axis
  let bar = 50 + 0.4 * expected;
  const Content = () => {
    if (data.days) {
      return (
        <VictoryChart domainPadding={{ x: 15 }}>
          <VictoryLabel
            text={`Total Company Hours`}
            textAnchor="middle"
            x={230}
            y={15}
          />
          <VictoryBar
            data={dataHold}
            x="day"
            y="time"
            labels={x => x.datum.time}
            style={{
              labels: { fill: 'black' },
              data: { fill: '#FF6A00' }
            }}
          />

          <VictoryAxis
            height={300}
            offsetY={bar}
            style={{
              axis: { strokeWidth: 0.3, strokeDasharray: '10 5' }
            }}
            tickFormat={x => ``}
          ></VictoryAxis>
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: maxWidthCheck ? 16 : 14
              },
              axis: { strokeWidth: 0.1 }
            }}
            tickValues={[1, 2, 3, 4]}
            tickFormat={xAxis}
          ></VictoryAxis>
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: {
                fontSize: maxWidthCheck ? 16 : 14
              },
              axis: { strokeWidth: 0.1 }
            }}
            tickValues={[0, 100, 200, 300, 400, 500]}
            tickFormat={x => `${x}`}
          ></VictoryAxis>
        </VictoryChart>
      );
    } else {
      return (
        <div className={classes.progressBar}>
          <CircularProgress
            size="65%"
            classes={{
              colorPrimary: classes.progressCircle
            }}
          />
        </div>
      );
    }
  };

  return (
    <Container className={classes.victoryContainer}>
      <Content />
    </Container>
  );
};

export default TimeChart;
