import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios/index';
import { ENTRYPOINT } from '../../../../config/entrypoint';
import { UploadFile } from '../../UploadFile';
import { getUserId, getToken } from '../../../../utils/auth';

// style

const fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const ModalContentWrapper = styled.div`
  animation: ${fadein} 500ms;

  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 753px;
`;

const ModalTitle = styled.div`
  background-color: #fff;
  padding: 18px 56px 5px 18px;
  font-size: 1.6rem;
  font-weight: 600;
  color: #52595f;

  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
`;

const FormCreateContainer = styled.div`
  padding: 20px;
  padding-bottom: 0;
  input {
    background: #ccc;
  }

  textarea {
    background: #ccc;
  }

  select {
    background: #ccc;
  }
`;

const ClientMappingSelectContainer = styled.div`
  display: ${props => {
    return props.isHideClientMapping ? 'none' : 'block';
  }};
  select {
    border-color: ${props => {
      return props.onInvalidClientMapping ? 'red' : 'transparent';
    }};
  }
`;

const SummaryInput = styled.input`
  border-color: ${props => {
    return props.onInvalidSummary ? 'red' : 'transparent';
  }};
`;

const ModalContent = styled.div`
  background-color: #ffffff;

  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: calc(100% - 76px);

  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 20px;
  margin: 10px;
`;

const SaveChangesButton = styled.input`
  display: block;
  color: white;
  transition: all 0.3s;
  background: #ccc

  // override Bootstrap style
  &,
  &.btn:disabled {
    background-color: #798186;
    border-color: #8a8e90;
    box-shadow: unset;
  }

  &:hover {
    color: white;
  }

  &.active {
    background-color: #979797;
    border-color: #979797;
    box-shadow: unset;

    &:hover {
      color: white;
    }
  }
`;

const ModalCreateTicket = styled.div`
  width: 100%;
`;

// main

const EditTicket = ({ ticketId, hideModal, handleReload, clientMappings }) => {
  const [ticket, setTicket] = useState({});
  const [submitSent, setSubmitSent] = useState(false);
  const [isHideClientMapping, setIsShowClientMapping] = useState(false);
  const [onInvalidSummary, setOnInvalidSummary] = useState(false);
  const [onInvalidClientMapping, setOnInvalidClientMapping] = useState(false);

  useEffect(() => {
    if (getUserId()) {
      axios({
        url: 'get_ticket/' + ticketId,
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      }).then(response => {
        setTicket(response.data);
      });
    }
  }, []);

  useEffect(() => {
    setIsShowClientMapping(clientMappings.length === 1);
  }, [isHideClientMapping]);

  const handleSubmitChange = event => {
    let values = {
      summary: ticket.summary,
      description: ticket.description,
      clientMapping: ticket.clientMapping,
      attachments: ticket.attachments
    };

    const ticketInfo = {
      ticketInfo: values
    };

    if (ticket.clientMapping && ticket.summary) {
      axios({
        url: 'edit_ticket/' + ticketId,
        baseURL: ENTRYPOINT,
        data: JSON.stringify(ticketInfo),
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'PUT'
      }).then(() => {
        handleReload();
        hideModal();
      });
    } else {
      !ticket.summary.length > 0
        ? setOnInvalidSummary(true)
        : setOnInvalidSummary(false);
      !ticket.clientMapping > 0
        ? setOnInvalidClientMapping(true)
        : setOnInvalidClientMapping(false);
      // hideModal();
    }
  };

  const handleChangeClientMapping = event => {
    const ticketChange = Object.assign({}, ticket, {
      clientMapping: event.target.value
    });
    setTicket(ticketChange);
    !event.target.value > 0
      ? setOnInvalidClientMapping(true)
      : setOnInvalidClientMapping(false);
  };

  const handleChangeSummary = event => {
    const ticketChange = Object.assign({}, ticket, {
      summary: event.target.value
    });
    setTicket(ticketChange);
    !event.target.value.length > 0
      ? setOnInvalidSummary(true)
      : setOnInvalidSummary(false);
  };

  const handleChangeDescritption = event => {
    const ticketChange = Object.assign({}, ticket, {
      description: event.target.value
    });
    setTicket(ticketChange);
  };

  const handleChangeAttachment = attachmentList => {
    const ticketChange = Object.assign({}, ticket, {
      attachments: attachmentList
    });
    setTicket(ticketChange);
  };

  return (
    <ModalContentWrapper>
      <ModalTitle>Edit Ticket</ModalTitle>
      <ModalContent>
        <ModalCreateTicket>
          <FormCreateContainer className="form-create-container">
            <ClientMappingSelectContainer
              className="form-group"
              isHideClientMapping={isHideClientMapping}
              onInvalidClientMapping={onInvalidClientMapping}
            >
              <label
                htmlFor="ticket_clientmapping"
                className="form-control-label"
              >
                Account
              </label>
              <select
                name="clientMapping"
                id="ticket_clientmapping"
                className="form-control"
                required=""
                onChange={handleChangeClientMapping}
              >
                <option></option>
                {clientMappings.map(clientMapping => {
                  return (
                    <option
                      key={clientMapping.id}
                      selected={ticket.clientMapping === clientMapping.id}
                      value={clientMapping.id}
                    >
                      {clientMapping.customer.customerName}
                    </option>
                  );
                })}
              </select>
            </ClientMappingSelectContainer>

            <div className="form-group">
              <label htmlFor="ticket_summary" className="form-control-label">
                Summary
              </label>
              <SummaryInput
                name="summary"
                id="ticket_summary"
                className="form-control"
                type="text"
                placeholder=""
                onInvalidSummary={onInvalidSummary}
                value={ticket.summary}
                onChange={handleChangeSummary}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="ticket_description"
                className="form-control-label"
              >
                Description
              </label>
              <textarea
                name="description"
                id="ticket_description"
                className="form-control"
                cols="5"
                placeholder=""
                value={ticket.description}
                onChange={handleChangeDescritption}
              />
            </div>

            <div className="form-group">
              <label
                htmlFor="ticket_attachments"
                className="form-control-label"
              >
                Attachment
              </label>
              <UploadFile
                handleChangeAttachment={handleChangeAttachment}
                ticket={ticket}
              />
            </div>
          </FormCreateContainer>

          <Buttons>
            <SaveChangesButton
              type="submit"
              value={submitSent ? 'Saving...' : 'Save'}
              className={'btn ' + (!submitSent ? 'active' : 'inactive')}
              onClick={handleSubmitChange}
            />
          </Buttons>
        </ModalCreateTicket>
      </ModalContent>
    </ModalContentWrapper>
  );
};

export default EditTicket;
