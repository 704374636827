import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { ENTRYPOINT } from '../../config/entrypoint';
import LinearProgressComponent from '../StatelessComponents/LinearProgressComponent';
import { makeStyles } from '@material-ui/core/styles';
import {
  getUserId,
  logout,
  isAuthorizedUser,
  getToken
} from '../../utils/auth';
import { RedirectToLogin } from '../../hooks';

const useStyles = makeStyles(() => ({
  InvoiceDashboardContent: {
    background: '#fff',
    margin: '0 20px',
    borderRadius: '5px'
  },
  InvoiceBlock: {
    margin: '15px 0'
  },
  StatusInvoiceContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  StatusInvoice: {
    '& .new': {
      background: '#ff851b',
      color: 'white',
      textAlign: 'center',
      padding: '5px 20px'
    },
    '& .paid': {
      background: '#1c7430',
      color: 'white',
      textAlign: 'center',
      padding: '5px 20px'
    },
    '& .overdue': {
      background: '#F95700',
      color: 'white',
      textAlign: 'center',
      padding: '5px 20px'
    }
  },
  InvoiceValue: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '36px',
    padding: '10px'
  },
  PayInvoice: {
    textAlign: 'center',
    marginBottom: '20px',
    '& a': {
      padding: '10px',
      background: '#1890ff',
      color: '#fff'
    }
  },
  Table: {
    textAlign: 'left',
    width: '100%',
    color: '#212529',
    '& th, & td': {
      padding: '0.5rem',
      borderBottom: '1px solid #dee2e6',
      maxWidth: '100px'
    },
    '& th': {
      fontWeight: 'bold',
      width: '130px'
    },
    '& .memo-label': {
      verticalAlign: 'top'
    },
    '& thead': {
      background: '#ECECEC'
    }
  },
  ViewInvoices: {
    textAlign: 'center',
    paddingTop: '10px',
    '& a': {
      color: '#ff851b'
    }
  },
  EmptyInvoice: {
    padding: '2px',
    margin: '20px',
    textAlign: 'center',
    fontWeight: 'bold'
  }
}));

const InvoicesDashboardContent = () => {
  const [invoice, setInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (getUserId()) {
      setIsLoading(true);
      axios({
        url: 'get_invoice_latest',
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          setInvoice(response.data[0]);
        })
        .catch(e => {})
        .finally(() => setIsLoading(false));
    }
  }, []);
  const classes = useStyles();
  if (!isAuthorizedUser()) {
    return <RedirectToLogin />;
  } else {
    return (
      <>
        {isLoading && <LinearProgressComponent />}
        {!invoice && <div className={classes.EmptyInvoice}>Empty Invoice</div>}
        {invoice && Object.entries(invoice).length !== 0 && (
          <div className={classes.InvoiceDashboardContent}>
            <div className={classes.InvoiceBlock}>
              <div className={classes.HeaderInvoice}>
                <div className={classes.StatusInvoiceContainer}>
                  <div>Last invoice</div>
                  <div className={classes.StatusInvoice}>
                    <div className={invoice.status}>{invoice.status}</div>
                  </div>
                </div>
                <div>
                  <div className={classes.InvoiceValue}>
                    {invoice.totalAmount}
                  </div>
                  {invoice.canPayInvoice && (
                    <div className={classes.PayInvoice}>
                      <a href={invoice.invoiceLink} target="_blank">
                        Pay Invoice
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <table className={classes.Table}>
                  <tbody>
                    <>
                      <tr>
                        <th>Invoice Number:</th>
                        <td>{invoice.docNumber}</td>
                      </tr>
                      <tr>
                        <th>Terms:</th>
                        <td>{invoice.termName}</td>
                      </tr>
                      <tr>
                        <th>Invoice Date:</th>
                        <td>{invoice.txnDate}</td>
                      </tr>
                      <tr>
                        <th>Due Date:</th>
                        <td>{invoice.dueDate}</td>
                      </tr>
                      {invoice.customerMemo && (
                        <tr>
                          <th className="memo-label">Memo:</th>
                          <td>{invoice.customerMemo}</td>
                        </tr>
                      )}
                    </>
                  </tbody>
                </table>
              </div>
              <div className={classes.ViewInvoices}>
                <a href="/invoices">view invoice</a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
};

export default InvoicesDashboardContent;
