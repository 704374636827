import React from 'react';
// import { Route } from 'react-router';
// import { PrivateLayout as Route } from '../components/PrivateLayout';
import ActivateAccount from '../components/account/ActivateAccount';
import { Route } from 'react-router';
export default [
  <Route
    path="/account/activate/:userName?/:tokenClient?"
    component={ActivateAccount}
  />
];
