import React, { useState, useEffect } from 'react';
import { fetch } from '../../utils/dataAccess';
import { Card, Typography, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    textAlign: 'center'
  },
  title: {
    fontSize: 14
  }
});

const Fundamentals = () => {
  const [data, setData] = useState({
    name: 'loading...',
    description: 'loading...'
  });

  useEffect(() => {
    const fetchData = async () => {
      const resp = await fetch('fundamental-of-the-day', {
        method: 'GET'
      })
        .then(response => {
          let x = response.json();
          return x;
        })
        .catch(err => {
          console.log(err);
        });
      setData(resp);
    };
    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          color="textSecondary"
          className={classes.title}
          gutterBottom
        >
          Fundamental of the Day
        </Typography>
        <Typography gutterBottom variant="h5" component="h2">
          {data.name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Fundamentals;
