import React, { useState, useEffect } from 'react';
import { HUBSPOT_API_KEY } from '../../config/entrypoint.js';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    textAlign: 'center',
    marginTop: '5px'
  },
  padding: {
    '&:last-child': {
      paddingBottom: '16px'
    }
  },
  title: {
    fontSize: 14
  }
});

const DealCountCard = () => {
  const [data, setData] = useState({
    count: 'loading...'
  });

  useEffect(() => {
    const month = 1000 * 60 * 60 * 24 * 30;
    const monthTime = new Date(Date.now() - month).getTime();
    const fetchData = async () => {
      const result = await fetch(
        `https://cors-anywhere.herokuapp.com/https://api.hubapi.com/deals/v1/deal/recent/created?hapikey=${HUBSPOT_API_KEY}&count=100&since=${monthTime}`
      )
        .then(x => x.json())
        .then(data => {
          return { count: data.results.length };
        })
        .catch(e => {
          console.log('Error with Hubspot API call');
          return { count: 'Hubspot API error' };
        });

      setData(result);
    };
    fetchData();
  }, []);

  const classes = useStyles();

  return (
    <Card className={classes.root} variant="outlined">
      <CardContent className={classes.padding}>
        <Typography variant="h6" component="h2" align="center">
          New deals in the past 30 days: {data.count}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DealCountCard;
