import React, { useEffect, useState } from 'react';
// import './WorkLogsDashboard.scss';
import {
  getToken,
  getUserId,
  isAuthorizedUser,
  logout
} from '../../utils/auth';
import { RedirectToLogin } from '../../hooks';
import axios from 'axios';
import { ENTRYPOINT } from '../../config/entrypoint';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  WorklogDashboardContent: {
    background: '#fff',
    margin: '0 20px',
    borderRadius: '5px'
  },
  Table: {
    textAlign: 'left',
    width: '100%',
    color: '#212529',
    '& th, & td': {
      padding: '0.5rem',
      borderBottom: '1px solid #dee2e6'
    },
    '& th': {},
    '& .memo-label': {
      verticalAlign: 'top'
    },
    '& thead': {
      background: '#ECECEC'
    }
  },
  headerWorkLogs: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    fontWeight: 'bold'
  },
  Hours: {
    width: '10%'
  },
  Account: {
    width: '20%'
  },
  Summary: {
    width: '70%'
  },
  Action: {
    width: '5%',
    textAlign: 'center'
  },
  NoWorklog: {
    padding: '2px',
    margin: '20px',
    textAlign: 'center',
    fontWeight: 'bold'
  }
}));
const WorkLogsDashboard = () => {
  const [workLogs, setWorkLogs] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (getUserId()) {
      axios({
        url: 'get_worklogs_list',
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          let wls = response.data;
          setWorkLogs(wls);
          setIsLoading(false);
        })
        .catch(e => {
          setErrorRequest(true);
        });
    }
  }, [isReload]);

  const classes = useStyles();

  if (!isAuthorizedUser()) {
    return <RedirectToLogin />;
  } else {
    return (
      <>
        {workLogs.length > 0 && (
          <div className={classes.WorklogDashboardContent}>
            <div className={classes.headerWorkLogs}>
              <div>Latest Worklog Activity</div>
              <div>{workLogs[0]['totalTime']}h total</div>
            </div>
            <table className={classes.Table}>
              <thead className="">
                <tr>
                  <th className={classes.Summary}>Summary</th>
                  <th className={classes.Account}>Account</th>
                  <th className={classes.Hours}>Hours</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {workLogs.slice(0, 5).map(item => (
                    <tr key={item['id']}>
                      <td>{item['issueSummary']}</td>
                      <td>{item['employeeName']}</td>
                      <td>{item['hours']}</td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
            <div className="view-all">
              <a href="/worklogs">view all work logs</a>
            </div>
          </div>
        )}

        {!isLoading && !workLogs.length > 0 && (
          <div className={classes.NoWorklog}>You have no worklog.</div>
        )}
      </>
    );
  }
};

export default WorkLogsDashboard;
