import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import TopContentRight from '../template/TopContentRight';
import InvoiceList from './InvoiceList';

const useStyles = makeStyles(() => ({
  InvoicesContentContainer: {
    width: '100%'
  }
}));

const InvoicesContent = () => {
  const classes = useStyles();
  return (
    <>
      <TopContentRight title="Invoices" />
      <div className="work-logs-invoices">
        <div className={classes.InvoicesContentContainer}>
          <InvoiceList />
        </div>
      </div>
    </>
  );
};

export default InvoicesContent;
