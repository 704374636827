import React, { useState, useEffect } from 'react';
import {
  FormGroup,
  FormControl,
  FormLabel,
  ButtonToolbar
} from 'react-bootstrap';
import { Button, Link } from '@material-ui/core';
import { BACKEND_URL, ADMIN_URL } from '../../config/entrypoint';
import logoEndertech from '../../css/images/general/endertech-icon.png';
import axios from 'axios/index';
import styled from 'styled-components';
import { Route, Redirect } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

import Snackbar from '@material-ui/core/Snackbar';

import { LinearLoaderComponent, AlertComponent } from '../StatelessComponents';
import { login, getToken } from '../../utils/auth';

const useStyles = makeStyles({
  button: {
    color: '#fff',
    borderRadius: 2,
    textTransform: 'capitalize'
  },
  LoginBox: {
    maxWidth: '500px',
    margin: '0 auto',
    paddingTop: '17vh'
  },
  LoginLogoContainer: {
    fontSize: '24px',
    textAlign: 'center',
    marginBottom: '25px',
    fontWeight: 'bold'
  },
  LoginLogoBox: {
    color: '#41464a',
    '&:hover': {
      color: '#41464a'
    }
  },
  LoginLogoText: {
    fontSize: '18px'
  },
  LoginLogo: {
    marginBotton: '15px'
  },
  Img: {
    width: '64px'
  },
  LoginText: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  LoginFormContainer: {
    background: '#fff',
    padding: '30px',
    borderRadius: '5px'
  },
  Label: {
    fontWeight: 'bold',
    color: '#a9a9aa'
  },
  FormControl: {
    fontSize: '0.8rem',
    background: '#ececec'
  },
  ForgetPassword: {
    color: '#4a4f5',
    fontWeight: 'bold',
    marginBottom: '0.5rem'
  }
});

function AdminRoute() {
  return (
    <>
      Admin User Detected. Click{' '}
      <Link href={ADMIN_URL}>
        <strong>here</strong>
      </Link>
    </>
  );
}

const Login = props => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [disable, setDisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null),
    [open, setOpen] = React.useState(false);

  const handleChangeUserName = event => {
    const userName = event.target.value;
    setUserName(userName);
  };

  const handleChangePassword = event => {
    const password = event.target.value;
    setPassword(password);
  };
  useEffect(() => {
    const validateForm = () => {
      setDisable(!(userName.length > 0 && password.length > 0));
    };
    validateForm();
  }, [userName, password]);

  const handleSubmit = e => {
    e.preventDefault();
    setIsLoading(true);
    var values = { username: userName, password: password };
    axios({
      url: 'login/login_check',
      baseURL: BACKEND_URL,
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data: JSON.stringify(values)
    })
      .then(response => {
        if (response.status == 200) {
          login(response.data.token);
        }
      })
      .catch(err => {
        const { response } = err;
        if (!!response && response.status === 403) {
          const {
            data: { message }
          } = response;

          switch (message) {
            case 'Admin User Detected':
              setError({
                type: 'info',
                message: <AdminRoute />
              });
              break;
            case 'Insufficient Role to Login':
              setError({
                type: 'warning',
                message
              });
              break;
            default:
              defaultAuthError();
          }
        } else {
          defaultAuthError();
        }
        setOpen(true);
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = (event, handler) => {
    if (handler === 'clickaway') {
      setOpen(false);
    }
    setOpen(false);
  };

  const defaultAuthError = () =>
    setError({
      type: 'error',
      message: 'There is an Authentication error occurred'
    });

  const parseJwt = token => {
    var base64Url = token.split('.')[1];
    var base64 = decodeURIComponent(
      atob(base64Url)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(base64);
  };

  const classes = useStyles();
  return (
    <>
      {isLoading && <LinearLoaderComponent />}
      <div className={classes.LoginBox}>
        {getToken() && (
          <Route render={() => <Redirect from="/" to="/dashboard/" />} />
        )}
        <div className={classes.LoginLogoContainer}>
          <a
            className={classes.LoginLogoBox}
            target="_blank"
            rel="noopener noreferrer"
            href="http://endertech.com"
          >
            <div className={classes.LoginLogo}>
              <img
                className={classes.Img}
                src={logoEndertech}
                alt="Endertech Logo"
              />
            </div>
            <div className={classes.LoginLogoText}>
              <span>Welcome back, to Endertech Portal.</span>
            </div>
          </a>
        </div>
        <div className="Login">
          {error && (
            <Snackbar
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <AlertComponent severity={error.type}>
                {error.message}
              </AlertComponent>
            </Snackbar>
          )}
          <form className={classes.LoginFormContainer} onSubmit={handleSubmit}>
            <div className={classes.LoginText}>Login</div>
            <FormGroup controlId="username" bssize="large">
              <FormLabel>Username</FormLabel>
              <FormControl
                className={classes.FormControl}
                autoFocus
                type="text"
                value={userName}
                onChange={handleChangeUserName}
                placeholder="Enter Username"
              />
            </FormGroup>
            <FormGroup controlId="password" bssize="large">
              <FormLabel>Password</FormLabel>
              <FormControl
                className={classes.FormControl}
                value={password}
                onChange={handleChangePassword}
                type="password"
                placeholder="Enter Password"
              />
            </FormGroup>
            <a
              className={classes.ForgetPassword}
              href={`${BACKEND_URL}admin/resetting/request`}
            >
              <span>Forgotten password?</span>
            </a>
            <ButtonToolbar>
              <Button
                variant="contained"
                size="large"
                color="secondary"
                disabled={disable || isLoading}
                type="submit"
                onClick={handleSubmit}
                className={classes.button}
              >
                Login
              </Button>
            </ButtonToolbar>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
