import React, { useState, useEffect } from 'react';
import axios from 'axios/index';
import { BACKEND_URL } from '../../config/entrypoint.js';
import {
  VictoryChart,
  VictoryTheme,
  VictoryAxis,
  VictoryBar,
  VictoryLabel
} from 'victory';
import { Container, useMediaQuery, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getUserId, getToken } from '../../utils/auth';

const TimeChart = () => {
  const [data, setData] = useState({
    empty: 'Empty Array'
  });
  const useStyles = makeStyles({
    victoryContainer: {
      backgroundColor: '#ffffff'
    },
    progressBar: {
      textAlign: 'center',
      padding: '50px'
    },
    progressCircle: {
      color: '#FF6A00'
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      // parameters to get time activities within a certain margin
      let today = new Date(Date.now());
      let beforeParam = new Date(today.toLocaleString());
      beforeParam = `${beforeParam.toLocaleDateString()}`;
      let preformattedParam = new Date(
        today.getTime() - 24 * 60 * 60 * 1000 * 6
      );
      preformattedParam.setHours(0);
      preformattedParam.setMinutes(0);
      preformattedParam.setSeconds(0);
      let afterParam = `${preformattedParam.toLocaleDateString()}`;

      const result = await axios({
        url: `api/employee_mappings?user[]=${getUserId()}`,
        baseURL: BACKEND_URL,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + getToken()
        }
      })
        .then(data => {
          const employeeId = data.data[0]['qboEmployee']['id'];
          return employeeId;
        })
        .then(data => {
          const resp = axios({
            url: `api/worklogs?employeeName[]=${data}&activityDate[before]=${beforeParam}&activityDate[after]=${afterParam}`,
            baseURL: BACKEND_URL,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + getToken()
            }
          }).then(response => {
            if (response.status == 200) {
              return JSON.stringify(response.data);
            }
          });
          return resp;
        });
      setData(JSON.parse(result));
    };
    fetchData();
  }, []);

  const maxWidth = useMediaQuery('(max-width:767px');
  const oneDay = 24 * 60 * 60 * 1000;
  let today = Date.now();
  let todayDate = new Date(today);
  let todayDateVal = todayDate.getTime();
  let prevWeek = new Date(todayDate.getTime() - oneDay * 6);

  //set the start of last week at 00:00:00 time
  prevWeek.setHours(0);
  prevWeek.setMinutes(0);
  prevWeek.setSeconds(0);
  prevWeek.setMilliseconds(0);
  let prevWeekVal = prevWeek.getTime();

  let dataHold = [];
  let week = {};

  for (let x = 0; x < 7; x++) {
    let thisDay = prevWeek.toLocaleDateString();
    week[thisDay] = 0;
    prevWeek.setDate(prevWeek.getDate() + 1);
  }
  if (!data['empty'])
    for (let x in data) {
      let retrieved = data[x]['activityDate'];
      let retrievedDate = new Date(retrieved);
      let [hours, minutes] = data[x]['time'].split(':');

      week[retrievedDate.toLocaleDateString()] += parseInt(hours, 10);
      week[retrievedDate.toLocaleDateString()] += parseInt(minutes) / 60;
    }

  for (let x = 0; x < 7; x++) {
    let current = Object.keys(week)[x];
    let shortenedDate = current.slice(0, current.length - 5);
    dataHold.push({ day: shortenedDate, time: week[Object.keys(week)[x]] });
  }

  const Content = () => {
    if (!data['empty']) {
      return (
        <VictoryChart
          domainPadding={{ x: 15 }}
          padding={30}
          style={{ data: { fill: '#FF6A00' } }}
        >
          <VictoryLabel
            text={`Employee Hours`}
            textAnchor="middle"
            x={230}
            y={15}
          />
          <VictoryBar
            data={dataHold}
            x="day"
            y="time"
            labels={x => x.datum.time}
            style={{
              labels: { fill: 'black' },
              data: { fill: '#FF6A00' }
            }}
          ></VictoryBar>
          <VictoryAxis
            height={300}
            offsetY={150}
            style={{
              axis: { strokeWidth: 0.3, strokeDasharray: '10 5' },
              tickLabels: { textAnchor: 'hello' }
            }}
            tickFormat={x => ``}
          ></VictoryAxis>
          <VictoryAxis
            style={{
              tickLabels: {
                fontSize: maxWidth ? 16 : 14
              },
              axis: { strokeWidth: 0.1 }
            }}
            tickValues={[1, 2, 3, 4]}
            tickFormat={Object.keys(week).map(e => e.slice(0, e.length - 5))}
          ></VictoryAxis>
          <VictoryAxis
            dependentAxis
            style={{
              tickLabels: {
                fontSize: maxWidth ? 16 : 14
              },
              axis: { strokeWidth: 0.1 }
            }}
            tickValues={[2, 4, 6, 8, 10, 12]}
            tickFormat={x => `${x}`}
          ></VictoryAxis>
        </VictoryChart>
      );
    } else {
      return (
        <div className={classes.progressBar}>
          <CircularProgress
            size="65%"
            classes={{
              colorPrimary: classes.progressCircle
            }}
          />
        </div>
      );
    }
  };

  const classes = useStyles();
  return (
    <Container className={classes.victoryContainer}>
      <Content> </Content>
    </Container>
  );
};

export default TimeChart;
