import React, { useState, useEffect } from 'react';

import { Upload } from 'antd';
import { ENTRYPOINT } from '../../config/entrypoint';
import 'antd/dist/antd.css';
import { getToken } from '../../utils/auth';

// main

const UploadFile = ({
  handleChangeAttachment,
  ticket,
  canUploadFile = true
}) => {
  const [fileList, setFileList] = useState('');
  const [previewShow, setPreviewShow] = useState(false);
  const [previewSrc, setPreviewSrc] = useState(null);

  useEffect(() => {
    setFileList(ticket.attachments);
  }, [ticket]);

  const getBase64Url = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  const onPreview = async file => {
    let previewSrc = file.url || file.preview;
    if (!previewSrc) {
      previewSrc = await getBase64Url(file.originFileObj);
    }
    setPreviewShow(true);
    setPreviewSrc(previewSrc);
  };

  // const getFileInfo = (file, fileList) => {
  //
  //   console.log(fileList);
  //   const reader = new FileReader();
  //
  //   reader.onload = e => {
  //     console.log(e.target.result);
  //   };
  //   reader.readAsText(file);
  //
  //   // Prevent upload
  //   return false;
  // };

  const onChange = ({ fileList }) => {
    setFileList(fileList);
    handleChangeAttachment(fileList);
  };

  const onDownload = file => {
    let urlDownload = file.url.replace('type=image', 'type=download');
    window.open(urlDownload, '_blank');
  };

  const fileUploadProps = {
    name: 'file',
    multiple: true,

    listType: 'picture-card',
    headers: {
      Authorization: 'Bearer ' + getToken(),
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    showUploadList: {
      showDownloadIcon: true,
      showPreviewIcon: false
    },
    action: ENTRYPOINT + 'upload_attachment_file'
  };

  return (
    <>
      <Upload
        {...fileUploadProps}
        fileList={fileList}
        onChange={onChange}
        onPreview={onPreview}
        onDownload={onDownload}
        // beforeUpload={getFileInfo}
      >
        {canUploadFile && <i className="fas fa-plus"></i>}
      </Upload>
    </>
  );
};

export { UploadFile };
