import React from 'react';
import styled from 'styled-components';
import avatarDefault from '../../css/images/general/avatar-default.png';
import { logout, isAdmin } from '../../utils/auth';
import { ADMIN_URL } from '../../config/entrypoint';

// style

const Container = styled.div`
  position: relative;

  ul {
    list-style: none;
    margin-bottom: 0;
    padding: 0;
  }

  ul ul {
    display: none;
    position: absolute;
  }

  ul ul {
    top: 0;
    right: 0;
  }

  ul li:hover > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    padding: 1rem 1.5rem 1rem 1rem;
    border: 1px solid #00000026;
    border-radius: 4px;
    background-color: #fff;
    z-index: 1;
  }
`;

const DropDownToggle = styled.div`
  font-size: 1.2rem;
  color: #ffffff;
  cursor: pointer;

  display: flex;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
`;

const Link = styled.li`
  display: flex;
  align-items: center;
  justify-content: left;
  min-width: 120px;
  :not(:last-of-type) {
    margin-bottom: 4px;
  }

  i {
    padding: 10px;
  }

  a {
    font-size: 0.9rem;
    letter-spacing: 0.01rem;
    color: #454a4e;
    transition: color 0.3s;

    &:hover {
      color: #42a8df;
      text-decoration: none;
    }

    &:not(:last-of-type) {
      margin-bottom: 0.6rem;
    }
  }
`;

// main

const Settings = props => {
  const { position } = props;

  return (
    <Container className={position} onClick={event => event.stopPropagation()}>
      <ul>
        <li>
          <DropDownToggle className={position}>
            <img src={avatarDefault} />
          </DropDownToggle>
          <ul>
            <Link>
              <i className="fas fa-sign-out-alt"></i>
              <a href="/login" onClick={() => logout()}>
                Logout
              </a>
            </Link>
            {isAdmin() && (
              <Link>
                <i className="fas fa-columns"></i>
                <a href={ADMIN_URL} target="_blank" rel="noopener noreferrer">
                  Admin Portal
                </a>
              </Link>
            )}
          </ul>
        </li>
      </ul>
    </Container>
  );
};

export default Settings;
