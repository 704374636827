import React from 'react';

import TopContentRight from '../template/TopContentRight';
import ClientDashboardContent from './ClientDashboardContent';
import EmployeeDashboardContent from './EmployeeDashboardContent';
import { getUserType } from '../../utils/auth';

function DashboardContent() {
  const userType = getUserType(),
    titleDashboard = `${userType} Dashboard`,
    contentDashboard = `${userType}DashboardContent`,
    ContentDashboardComponent = componentsObject[contentDashboard];

  return (
    <>
      {!userType ? (
        <em>Something is wrong. Please reload.</em>
      ) : (
        <>
          <TopContentRight title={titleDashboard} />
          <ContentDashboardComponent />
        </>
      )}
    </>
  );
}

export default DashboardContent;

const componentsObject = {
  EmployeeDashboardContent,
  ClientDashboardContent
};
