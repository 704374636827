import React from 'react';
import styled, { keyframes } from 'styled-components';

// symbols

const ModalStyles = {
  CONFIRMATION: Symbol('confirmation'),
  NOTIFICATION: Symbol('notification')
};

const ModalCloseStyles = {
  MANUAL: Symbol('manual'),
  AUTO: Symbol('auto')
};

// style

const fadein = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const fadeout = keyframes`
  from { opacity: 1; }
  to   { opacity: 0; }
`;

const Container = styled.div`
  animation: ${fadein} 500ms;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
  z-index: 3;

  display: ${props => (props.show ? 'block' : 'none')};

  &.fading {
    animation: ${fadeout} 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
`;

const ModalMain = styled.div`
  position: fixed;
  max-width: 80vw;
  height: auto;
  top: 50%;
  left: 50%;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.13);
  overflow: hidden;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

const ModalClose = styled.div`
  position: absolute;
  right: 10px;
  top: 18px;
  width: 36px;
  height: 36px;
  z-index: 3;

  /* center align items */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CloseIcon = styled.i`
  color: #787879;
  font-size: 1.7rem;
  cursor: pointer;
`;

// main

class Modal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // add outside click handler
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // remove outside click handler
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleClickOutside = event => {
    if (!this.props.show) {
      return;
    }
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.hideModal();
    }
  };

  render() {
    const { show, modalStyle, modalFading } = this.props;
    const { hideModal } = this.props;
    const { children } = this.props;

    return (
      <Container show={show} className={modalFading ? 'fading' : ''}>
        <ModalMain ref={this.setWrapperRef} className="modal-main">
          {children}
          {modalStyle === ModalStyles.CONFIRMATION && (
            <ModalClose onClick={hideModal}>
              <CloseIcon className="fa fa-times" aria-hidden="true"></CloseIcon>
            </ModalClose>
          )}
        </ModalMain>
      </Container>
    );
  }
}

export { ModalStyles, ModalCloseStyles, Modal };
