import React, { useEffect, useState } from 'react';

import TicketList from '../tickets/TicketList';
import TopContentRight from '../template/TopContentRight';

const TicketsContent = props => {
  const [isOpenCreateTicketModal, setIsOpenCreateTicketModal] = useState(false);

  useEffect(() => {
    if (props.location.hash === '#create') {
      setIsOpenCreateTicketModal(true);
    }
  }, []);

  return (
    <>
      <TopContentRight title="Tickets" />
      <TicketList isOpenCreateTicketModal={isOpenCreateTicketModal} />
    </>
  );
};

export default TicketsContent;
