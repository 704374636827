import { useHistory } from 'react-router-dom';
import { logout } from '../utils/auth';

function RedirectToLogin() {
  logout();
  const history = useHistory();
  history.push('/login/');
  return null;
}

export { RedirectToLogin };
