import React from 'react';
import WorkLogsDashboard from '../worklogs/WorkLogsDashboard';
import InvoicesDashboardContent from '../invoices/InvoicesDashboardContent';
import TicketsDashboardContent from '../tickets/TicketsDashboardContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  TicketsDashboardContainer: {
    background: '#fff',
    margin: '0 16px',
    borderRadius: '5px'
  },
  WorkLogsDashboardContainer: {
    background: '#FFF',
    margin: '10px',
    borderRadius: '5px',
    width: '50%'
  },
  InvoicesDashboardContainer: {
    background: '#FFF',
    margin: '10px',
    borderRadius: '5px'
  },
  WorkLogsInvoices: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  ClientDashboardContainer: {}
});
const ClientDashboardContent = () => {
  const classes = useStyles();
  return (
    <div className={classes.ClientDashboardContainer}>
      <div className={classes.WorkLogsInvoices}>
        <div className={classes.WorkLogsDashboardContainer}>
          <WorkLogsDashboard />
        </div>
        <div className={classes.WorkLogsDashboardContainer}>
          <InvoicesDashboardContent />
        </div>
      </div>
      <div className={classes.TicketsDashboardContainer}>
        <TicketsDashboardContent />
      </div>
    </div>
  );
};

export default ClientDashboardContent;
