import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ENTRYPOINT } from '../../config/entrypoint';
import { Redirect, Route } from 'react-router';
import { logout, getUserId, getToken } from '../../utils/auth';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  TicketsBlock: {
    margin: '15px'
  },
  HeaderTickets: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px 0',
    fontWeight: 'bold',
    alignItems: 'center'
  },
  CreateTicketContainer: {
    padding: '10px 0'
  },
  CreateTicketBtn: {
    '& a': {
      background: '#FF6A00',
      color: '#fff',
      padding: '10px 30px'
    }
  },
  TicketsTable: {
    width: '100%',
    color: '#212529',
    marginBottom: '1rem',

    '& thead': {
      background: '#ECECEC'
    },

    '& tbody': {
      background: '#fff'
    },

    '& th, & td': {
      padding: '10px'
    },
    '& td': {
      borderBottom: '1px solid #dee2e6'
    }
  },

  Summary: {
    width: '45%'
  },
  Account: {
    width: '35%'
  },

  Status: {
    width: '10%'
  },
  Action: {
    width: '5%',
    textAlign: 'center'
  },
  CurrentStatus: {
    '& .new': {
      background: '#ff851b',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .pending': {
      background: '#0b6a74',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .inprogress': {
      background: '#744c09',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .done': {
      background: '#1c7430',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    },
    '& .archive': {
      background: '#690674',
      color: 'white',
      textAlign: 'center',
      padding: '0 5px',
      borderRadius: '3px'
    }
  },
  ViewAll: {
    textAlign: 'center',
    '& a': {
      color: '#ff851b'
    }
  }
}));

const TicketsDashboardContent = () => {
  const [isReload, setIsReload] = useState(false);
  const [errorRequest, setErrorRequest] = useState(false);
  const [tickets, setTickets] = useState('');

  useEffect(() => {
    if (getUserId()) {
      axios({
        url: 'get_ticket_list',
        baseURL: ENTRYPOINT,
        headers: { Authorization: 'Bearer ' + getToken() },
        method: 'GET'
      })
        .then(response => {
          let ticketNormal = response.data.filter(
            ticket => ticket.ticketStatus !== 'Archive'
          );
          setTickets(ticketNormal);
        })
        .catch(e => {
          setErrorRequest(true);
          logout();
        });
    }
  }, [isReload]);

  const classes = useStyles();

  return (
    <div>
      {errorRequest && (
        <Route render={() => <Redirect from="/" to="/login/" />} />
      )}
      <>
        {tickets.length > 0 && (
          <div className={classes.TicketsBlock}>
            <div className={classes.HeaderTickets}>
              <div>Recently Updated Tickets</div>
              <div className={classes.CreateTicketContainer}>
                <div className={classes.CreateTicketBtn}>
                  <Link to="/tickets#create">Create Ticket</Link>
                </div>
              </div>
            </div>
            <table className={classes.TicketsTable}>
              <thead>
                <tr>
                  <th className={classes.Summary}>Summary</th>
                  <th className={classes.Account}>Account</th>
                  <th className={classes.Status}>Status</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {tickets.slice(0, 5).map(item => (
                    <tr key={item['id']}>
                      <td>{item['summary']}</td>
                      <td>{item['clientMapping']}</td>
                      <td className={classes.CurrentStatus}>
                        <div className={item['ticketStatusSlug']}>
                          {item['ticketStatus']}
                        </div>
                      </td>
                    </tr>
                  ))}
                </>
              </tbody>
            </table>
            <div className={classes.ViewAll}>
              <Link to="/tickets">view all tickets</Link>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default TicketsDashboardContent;
